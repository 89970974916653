import React from "react"

const ToggleOn: React.FC<React.SVGAttributes<HTMLOrSVGElement>> = (props) => {
  const { ...rest } = props

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="16"
      viewBox="0 0 28 16"
      fill="none"
      {...rest}
    >
      <g clipPath="url(#clip0_821_5236)">
        <path
          d="M0 8C0 3.58172 3.58172 0 8 0H20C24.4183 0 28 3.58172 28 8C28 12.4183 24.4183 16 20 16H8C3.58172 16 0 12.4183 0 8Z"
          fill="#46A758"
        />
        <g filter="url(#filter0_d_821_5236)">
          <circle cx="20" cy="8" r="6" fill="white" />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_d_821_5236"
          x="12"
          y="1"
          width="16"
          height="16"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_821_5236"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_821_5236"
            result="shape"
          />
        </filter>
        <clipPath id="clip0_821_5236">
          <path
            d="M0 8C0 3.58172 3.58172 0 8 0H20C24.4183 0 28 3.58172 28 8C28 12.4183 24.4183 16 20 16H8C3.58172 16 0 12.4183 0 8Z"
            fill="white"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export default ToggleOn
