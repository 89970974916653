import CarouselSlides from "./carousel-slides"
import Darkmoon from "./dark-moon"
import FileText from "./file-text"
import GraduateCap from "./graduate-cap"
import Group2Users from "./group2-users"
import ImacComputer from "./imac-computer"
import LayoutLeft from "./layout-left"
import LuggageBag from "./luggage-bag"
import SunLight from "./sun-light"
import TelevisionOld from "./television-old"
import ThumbsUp from "./thumbs-up"
import Toolbox from "./toolbox"
import BookGuide from "./book-guide"
import EmailEnvelope from "./email-envelope"
import MedalTrophy from "./media-trophy"
import SpaceStar from "./space-star"
import TvMonitor from "./tv-monitor"
import WriteEditList from "./write-edit-list"
import CopyOutline from "./copy-outline"
import Layers2 from "./layers-2"
import Sparkle2 from "./sparkle-2"
import Photos from "./photos"

export const outline = {
  ThumbsUp,
  TelevisionOld,
  SunLight,
  Darkmoon,
  ImacComputer,
  FileText,
  GraduateCap,
  Group2Users,
  LayoutLeft,
  LuggageBag,
  Toolbox,
  CarouselSlides,
  BookGuide,
  EmailEnvelope,
  MedalTrophy,
  SpaceStar,
  TvMonitor,
  WriteEditList,
  CopyOutline,
  Layers2,
  Sparkle2,
  Photos,
}
