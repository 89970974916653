import CircleExclamation from "./circle-exclamation"
import ThumbsUp from "./thumbs-up"
import CircleCheck from "./circle-check"
import BuildingCompany from "./building-company"
import BubbleMessage from "./bubble-message"
import MagnifyingGlass from "./magnifying-glass"
import AlignJustify from "./align-justify"
import Bag from "./bag"
import Book from "./book"
import Code from "./code"
import FileText from "./file-text"
import Group2 from "./group-2"
import News1 from "./news-1"
import Notebook from "./notebook"
import Write from "./write"
import Envelope from "./envelope"
import DotGrid from "./dot-grid"
import BarsThree from "./bars-three"
import ToggleOff from "./toggle-off"
import ToggleOn from "./toggle-on"

export const solid = {
  ThumbsUp,
  CircleExclamation,
  CircleCheck,
  BuildingCompany,
  BubbleMessage,
  MagnifyingGlass,
  AlignJustify,
  Bag,
  Book,
  Code,
  FileText,
  Group2,
  News1,
  Notebook,
  Write,
  Envelope,
  DotGrid,
  BarsThree,
  ToggleOff,
  ToggleOn,
}
