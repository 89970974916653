import React from "react"

const Photos: React.FC<React.SVGAttributes<HTMLOrSVGElement>> = (props) => {
  const { ...rest } = props

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 6.5C3 4.84315 4.34315 3.5 6 3.5H18C19.6569 3.5 21 4.84315 21 6.5V18.5C21 20.1569 19.6569 21.5 18 21.5H6C4.34315 21.5 3 20.1569 3 18.5V6.5ZM5 16.9142V18.5C5 19.0523 5.44772 19.5 6 19.5H17.5858L14 15.9142L12 17.9142L8 13.9142L5 16.9142ZM19 18.0858V6.5C19 5.94772 18.5523 5.5 18 5.5H6C5.44772 5.5 5 5.94772 5 6.5V14.0858L6.58579 12.5C7.36684 11.7189 8.63317 11.719 9.41421 12.5L12 15.0858L12.5858 14.5C13.3668 13.719 14.6332 13.719 15.4142 14.5L19 18.0858ZM12.25 9.5C12.25 8.39543 13.1454 7.5 14.25 7.5C15.3546 7.5 16.25 8.39543 16.25 9.5C16.25 10.6046 15.3546 11.5 14.25 11.5C13.1454 11.5 12.25 10.6046 12.25 9.5Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default Photos
